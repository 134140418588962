<template>
	<div class="app-main__inner">
		<div class="container mb-3">
			<div class="row flex flex-col md:flex-row justify-between align-items-center mb-3">
				<div class="invite-header">
					<div class="flex justify-between align-items-center space-x-3 mb-3">
						<span class="text-sm md:text-2xl  font-semibold text-gray-900 dashboard-head-size">{{
								$t('inviteCustomer')
							}}</span>
					</div>
				</div>
				<div
					class="flex justify-between align-items-center tracking-wide border rounded-md bg-white text-xs md:text-xl">
					<span @click="showTab(1)"
						  class="text-xs h-10 tracking-wide flex justify-center align-items-center cursor-pointer px-3 py-2 rounded-l-md"
						  :class="tab === 1 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
							$t('invited')
						}}</span>
					<span @click="showTab(2)"
						  class="text-xs h-10 tracking-wide flex justify-center align-items-center cursor-pointer px-3 py-2"
						  :class="tab === 2 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
							$t('notInvited')
						}}</span>
					<span @click="showTab(4)"
						  class="text-xs h-10 tracking-wide flex justify-center align-items-center cursor-pointer px-3 py-2"
						  :class="tab === 4 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
							$t('accessRequest')
						}}</span>
					<span @click="showTab(3)"
						  class="text-xs h-10 tracking-wide flex justify-center align-items-center cursor-pointer px-3 py-2 rounded-r-md"
						  :class="tab === 3 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
							$t('connected')
						}}</span>
				</div>
			</div>

			<div class="row">
				<div class="w-full flex justify-center md:justify-end mb-3 bg-white border rounded-md p-2">
					<div data-cy='invite-table-top'
						 class="flex flex-col md:flex-row justify-end align-items-center space-x-4 space-y-3 md:space-y-0 w-full">
						<!-- <span class="cursor-pointer" @click="multipleAssignRelevant" v-if="GET_SHOP_CUSTOMERS_CHECKED && GET_SHOP_CUSTOMERS_CHECKED.status">
							<span class="flex justify-between align-items-center space-x-4 text-white bg-blue-900 border px-3 py-2 rounded-md">
								{{ $t('massAssign') }}
							</span>
						</span> -->
						<span
							class="flex justify-between align-items-center space-x-4 font-semibold px-3 py-2 rounded-md">
							{{ total_invited_customers }} {{ $t('connectedBuyers') }}
							</span>
						<div class="w-full lg:w-4/12">
							<input v-model="searchName" type="text" @input="searchInputTrigger"
								   class="flex justify-between w-full align-items-center space-x-4 focus:outline-none bg-gray-200 border px-3 py-2 rounded-md text-gray-500"
								   v-bind:placeholder="$t('search')">
						</div>
					</div>
				</div>
			</div>


			<div class="row dm-sans-font" v-if="tab === 1">
				<div class="w-full mt-1"> <!--pl-5 pr-5-->
					<div data-cy='invite-table' class="table-responsive rounded-lg bg-white border">
						<table v-loading='loading' style="width: 100%;" class="table table-hover table-bordered">
							<thead>
							<tr>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{
											$t('customerName')
										}}<span class="flex flex-col"><i @click="sortCustomer('customer_name')"
																		 class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortCustomer('-customer_name')"
											class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{
											$t('number')
										}}<span class="flex flex-col"><i @click="sortCustomer('customer_number')"
																		 class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortCustomer('-customer_number')"
											class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between">{{
											$t('organizationNumber')
										}}<span></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{
											$t('category')
										}}<span class="flex flex-col"><i @click="sortCategoryAsc"
																		 class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortCategoryDsc" class="lnr-chevron-down cursor-pointer small"></i></span>
									</div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{
											$t('email')
										}}<span class="flex flex-col"><i @click="sortEmailAsc"
																		 class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortEmailDsc"
											class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between">{{ $t('status') }}<span></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between">{{ $t('actions') }}<span></span></div>
								</th>
							</tr>
							</thead>
							<tbody>
							<tr v-for="(customer, i) in GET_INVITED_CUSTOMERS.data" :key="i">
								<td class="text-xs">{{ customer.customer_name }}</td>
								<td class="text-xs">{{ customer.customer_number }}</td>
								<td class="text-xs">
									<span v-if="customer.organization_number === ''">---</span>
									<span v-else>{{ $services.helpers.cleanData(customer.organization_number) }}</span>
								</td>
								<td class="text-xs">
									<span v-if="customer.category === null">---</span><span
									v-else>{{ customer.category.name }}</span>
								</td>
								<td class="text-xs">
									<span v-if="customer.invite_email !== null">
										{{ customer.invite_email }}
									</span>
									<span v-else>
										{{ $services.helpers.cleanData(customer.email) }}
									</span>
								</td>
								<td class="text-xs">
                                        <span v-if="customer.invitation_status === 'NotSent'" :title="$t('notInvited')">
                                            <span class="whitespace-nowrap">{{ $t('notInvited') }}</span>
											<!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-400 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
											<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
											</svg> -->
                                        </span>
									<span v-if="customer.invitation_status === 'Pending'"
										  :title="$t('pendingInvitation')">
                                            <span class="whitespace-nowrap">{{ $t('pendingInvitation') }}</span>
										<!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-yellow-300 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
										<path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
										</svg> -->
                                        </span>
									<span v-if="customer.invitation_status === 'Completed'" :title="$t('accepted')">
                                            <span class="text-green-500 whitespace-nowrap">{{ $t('accepted') }}</span>
										<!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
										<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
										</svg> -->
                                        </span>
								</td>
								<td>
									<!-- <DownloadTableManager :data='customer' /> -->
									<span class="flex justify-center space-x-2">
										<span v-if="customer.invitation_status === 'Completed'"
											  class="px-3 py-1 bg-green-500 text-white">{{ $t('confirmed') }}</span>

									<span class="flex space-x-2" v-if="customer.invitation_status === 'RequestAccess'">
                                            <span @click="acceptUserRequest(customer)"
												  class="bg-green-500 text-white font-black px-3 rounded cursor-pointer">{{
													$t('accept')
												}}</span>
                                            <span @click="rejectUserRequest(customer)"
												  class="bg-red-400 text-white font-black px-3 rounded cursor-pointer">{{
													$t('reject')
												}}</span>
                                        </span>
									<span
										@click="sendInviteData(customer)"
										class="flex justify-center align-items-center h-7 w-7 rounded-full bg-blue-900 cursor-pointer"><img
										src="./../../assets/images/icons/edit.png" alt=""></span>
									<span v-on:click="deleteUser(customer)"
										  class="flex justify-center align-items-center h-7 w-7 rounded-full bg-red-600 cursor-pointer">
                                                <img src="./../../assets/images/icons/delete.png" alt="">
                                            </span>
									</span>

								</td>
							</tr>
							<!-- <tr v-if="history.Invoices !== undefined && history.Invoices.length < 1" class="odd text-center"><td colspan="5" class="dataTables_empty text-xs" valign="top">{{ $t('noDataTable') }}</td></tr> -->
							</tbody>
						</table>
					</div>
					<div class="flex flex-col md:flex-row justify-between mb-4">
                        <span class="flex justify-between align-items-center space-x-5 mt-4">
                            <span class="text-base">{{ $t('show') }}</span>
                            <span>
                                <select v-model="limit" class="form-select" @change="shopResultNumber">
                                    <option value="10" selected>10</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                </select>
                            </span>
                        </span>
						<div class="pagination-block" v-if="GET_INVITED_CUSTOMERS.data"><!--GET_ARTICLES.total_pages--->
							<paginate
								:page-count="GET_INVITED_CUSTOMERS.total_pages"
								:click-handler="changePageNumber"
								prev-text="<"
								next-text=">"
								:container-class="'pagination space-x-2'">
							</paginate>
						</div>
					</div>
					<!-- <div class="pagination-block" v-if="GET_INVITED_CUSTOMERS.data">
						<paginate
						:page-count="GET_INVITED_CUSTOMERS.total_pages"
						:click-handler="changePageNumber"
						prev-text="<"
						next-text=">"
						:container-class="'pagination'">
						</paginate>
					</div> -->
				</div>
			</div>

			<div class="row dm-sans-font" v-if="tab === 2">
				<div class="w-full mt-1"> <!--pl-5 pr-5-->
					<div data-cy='invite-table' class="table-responsive rounded-lg bg-white border">
						<table v-loading='loading' style="width: 100%;" class="table table-hover table-bordered">
							<thead>
							<tr>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{
											$t('customerName')
										}}<span class="flex flex-col"><i @click="sortCustomer('customer_name')"
																		 class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortCustomer('-customer_name')"
											class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{
											$t('number')
										}}<span class="flex flex-col"><i @click="sortCustomer('customer_number')"
																		 class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortCustomer('-customer_number')"
											class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between">{{
											$t('organizationNumber')
										}}<span></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{
											$t('category')
										}}<span class="flex flex-col"><i @click="sortCategoryAsc"
																		 class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortCategoryDsc" class="lnr-chevron-down cursor-pointer small"></i></span>
									</div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{
											$t('email')
										}}<span class="flex flex-col"><i @click="sortEmailAsc"
																		 class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortEmailDsc"
											class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between">{{ $t('status') }}<span></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between">{{ $t('actions') }}<span></span></div>
								</th>
							</tr>
							</thead>
							<tbody>
							<tr v-for="(customer, i) in GET_INVITED_CUSTOMERS.data" :key="i">
								<td class="text-xs">{{ customer.customer_name }}</td>
								<td class="text-xs">{{ customer.customer_number }}</td>
								<td class="text-xs">
									<span v-if="customer.organization_number === ''">---</span>
									<span v-else>{{ $services.helpers.cleanData(customer.organization_number) }}</span>
								</td>
								<td class="text-xs">
									<span v-if="customer.category === null">---</span><span
									v-else>{{ customer.category.name }}</span>
								</td>
								<td class="text-xs">
									<span>
										{{ $services.helpers.cleanData(customer.email) }}
									</span>
								</td>
								<td class="text-xs">
                                        <span v-if="customer.invitation_status === 'NotSent'" :title="$t('notInvited')">
                                            <span class="whitespace-nowrap">{{ $t('notInvited') }}</span>
											<!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-400 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
											<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
											</svg> -->
                                        </span>
									<span v-if="customer.invitation_status === 'Pending'"
										  :title="$t('pendingInvitation')">
                                            <span class="whitespace-nowrap">{{ $t('pendingInvitation') }}</span>
										<!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-yellow-300 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
										<path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
										</svg> -->
                                        </span>
									<span v-if="customer.invitation_status === 'Completed'" :title="$t('accepted')">
                                            <span class="text-green-500 whitespace-nowrap">{{ $t('accepted') }}</span>
										<!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
										<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
										</svg> -->
                                        </span>
								</td>
								<td>
									<!-- <DownloadTableManager :data='customer' /> -->
									<span v-if="customer.invitation_status === 'Completed'"
										  class="px-3 py-1 bg-green-500 text-white whitespace-nowrap">{{
											$t('confirmed')
										}}</span>
									<span v-if="customer.invitation_status === 'Pending'"
										  class="text-white px-3 py-1 rounded cursor-pointer bg-blue-400 whitespace-nowrap">{{
											$t('waitingInvite')
										}}</span>
									<span class="flex space-x-2" v-if="customer.invitation_status === 'RequestAccess'">
                                            <span @click="acceptUserRequest(customer)"
												  class="bg-green-500 text-white font-black px-3 rounded cursor-pointer whitespace-nowrap">{{
													$t('accept')
												}}</span>
                                            <span @click="rejectUserRequest(customer)"
												  class="bg-red-400 text-white font-black px-3 rounded cursor-pointer whitespace-nowrap">{{
													$t('reject')
												}}</span>
                                        </span>
									<span v-if="customer.invitation_status === 'NotSent'"
										  @click="sendInviteData(customer)"
										  class="text-white px-3 py-1 rounded cursor-pointer bg-yellow-500 whitespace-nowrap">{{
											$t('invite')
										}}</span>
								</td>
							</tr>
							<!-- <tr v-if="history.Invoices !== undefined && history.Invoices.length < 1" class="odd text-center"><td colspan="5" class="dataTables_empty text-xs" valign="top">{{ $t('noDataTable') }}</td></tr> -->
							</tbody>
						</table>
					</div>
					<div class="flex flex-col md:flex-row justify-between mb-4">
                        <span class="flex justify-between align-items-center space-x-5 mt-4">
                            <span class="text-base">{{ $t('show') }}</span>
                            <span>
                                <select v-model="limit" class="form-select" @change="shopResultNumber">
                                    <option value="10" selected>10</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                </select>
                            </span>
                        </span>
						<div class="pagination-block" v-if="GET_INVITED_CUSTOMERS.data"><!--GET_ARTICLES.total_pages--->
							<paginate
								:page-count="GET_INVITED_CUSTOMERS.total_pages"
								:click-handler="changePageNumber"
								prev-text="<"
								next-text=">"
								:container-class="'pagination space-x-2'">
							</paginate>
						</div>
					</div>
					<!-- <div class="pagination-block" v-if="GET_INVITED_CUSTOMERS.data">
						<paginate
						:page-count="GET_INVITED_CUSTOMERS.total_pages"
						:click-handler="changePageNumber"
						prev-text="<"
						next-text=">"
						:container-class="'pagination'">
						</paginate>
					</div> -->
				</div>
			</div>

			<div class="row dm-sans-font" v-if="[4,3].includes(parseInt(tab)) === true">
				<div class="w-full mt-1"> <!--pl-5 pr-5-->
					<div data-cy='invite-table' class="table-responsive rounded-lg bg-white border">
						<table v-loading='loading' style="width: 100%;" id="exampledestroy"
							   class="table table-hover table-bordered">
							<thead>
							<tr>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{
											$t('customerName')
										}}<span class="flex flex-col"><i @click="sortCustomer('customer_name')"
																		 class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortCustomer('-customer_name')"
											class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{
											$t('number')
										}}<span class="flex flex-col"><i @click="sortCustomer('customer_number')"
																		 class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortCustomer('-customer_number')"
											class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between">{{
											$t('organizationNumber')
										}}<span></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{
											$t('category')
										}}<span class="flex flex-col"><i @click="sortCategoryAsc"
																		 class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortCategoryDsc" class="lnr-chevron-down cursor-pointer small"></i></span>
									</div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{
											$t('email')
										}}<span class="flex flex-col"><i @click="sortEmailAsc"
																		 class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortEmailDsc"
											class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between">{{ $t('status') }}<span></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between">{{ $t('actions') }}<span></span></div>
								</th>
							</tr>
							</thead>
							<tbody>
							<tr v-for="(customer, i) in GET_INVITED_CUSTOMERS.data" :key="i">
								<td class="text-xs">{{ customer.customer_name }}</td>
								<td class="text-xs">{{ customer.customer_number }}</td>
								<td class="text-xs">
									<span v-if="customer.organization_number === ''">---</span>
									<span v-else>{{ $services.helpers.cleanData(customer.organization_number) }}</span>
								</td>
								<td class="text-xs">
									<span v-if="customer.category === null">---</span><span
									v-else>{{ customer.category.name }}</span>
								</td>
								<td class="text-xs">{{ customer.email }}</td>
								<td class="text-xs">
                                        <span v-if="customer.invitation_status === 'NotSent'" :title="$t('notInvited')">
                                            <span class="whitespace-nowrap">{{ $t('notInvited') }}</span>
											<!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-400 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
											<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
											</svg> -->
                                        </span>
									<span v-if="customer.invitation_status === 'Pending'"
										  :title="$t('pendingInvitation')">
                                            <span class="whitespace-nowrap">{{ $t('pendingInvitation') }}</span>
										<!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-yellow-300 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
										<path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
										</svg> -->
                                        </span>
									<span v-if="customer.invitation_status === 'Completed'" :title="$t('accepted')">
                                            <span class="text-green-500 whitespace-nowrap">{{ $t('accepted') }}</span>
										<!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
										<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
										</svg> -->
                                        </span>
								</td>
								<td>
									<!-- <DownloadTableManager :data='customer' /> -->
									<span v-if="customer.invitation_status === 'Completed'"
										  class="px-3 py-1 bg-green-500 text-white">{{ $t('confirmed') }}</span>
									<span v-if="customer.invitation_status === 'Pending'"
										  class="text-white px-3 py-1 rounded cursor-pointer bg-blue-400">{{
											$t('waitingInvite')
										}}</span>
									<span class="flex space-x-2" v-if="customer.invitation_status === 'RequestAccess'">
                                            <span @click="acceptUserRequest(customer)"
												  class="bg-green-500 py-[4px] text-white font-black px-3 rounded cursor-pointer">{{
													$t('accept')
												}}</span>
                                            <span @click="rejectUserRequest(customer)"
												  class="bg-red-400 py-[4px] text-white font-black px-3 rounded cursor-pointer">{{
													$t('reject')
												}}</span>
                                        </span>
									<span v-if="customer.invitation_status === 'NotSent'"
										  @click="sendInviteData(customer)"
										  class="text-white px-3 py-1 rounded cursor-pointer bg-yellow-500">{{
											$t('invite')
										}}</span>
								</td>
							</tr>
							<!-- <tr v-if="history.Invoices !== undefined && history.Invoices.length < 1" class="odd text-center"><td colspan="5" class="dataTables_empty text-xs" valign="top">{{ $t('noDataTable') }}</td></tr> -->
							</tbody>
						</table>
					</div>
					<div class="flex flex-col md:flex-row justify-between mb-4">
                        <span class="flex justify-between align-items-center space-x-5 mt-4">
                            <span class="text-base">{{ $t('show') }}</span>
                            <span>
                                <select v-model="limit" class="form-select" @change="shopResultNumber">
                                    <option value="10" selected>10</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                </select>
                            </span>
                        </span>
						<div class="pagination-block" v-if="GET_INVITED_CUSTOMERS.data"><!--GET_ARTICLES.total_pages--->
							<paginate
								:page-count="GET_INVITED_CUSTOMERS.total_pages"
								:click-handler="changePageNumber"
								prev-text="<"
								next-text=">"
								:container-class="'pagination space-x-2'">
							</paginate>
						</div>
					</div>
					<!-- <div class="pagination-block" v-if="GET_INVITED_CUSTOMERS.data">
						<paginate
						:page-count="GET_INVITED_CUSTOMERS.total_pages"
						:click-handler="changePageNumber"
						prev-text="<"
						next-text=">"
						:container-class="'pagination'">
						</paginate>
					</div> -->
				</div>
			</div>
		</div>
		<Notification/>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
import debounce from 'lodash.debounce'
import bus from './../../bus'
import Paginate from 'vuejs-paginate'
// import i18n from './../../plugins/i18n'
// import DownloadTableManager from './../../customComponent/DownloadTableManager'

export default {
	name: 'invite',
	components: {
		Notification: () => import('./../../customComponent/NotificationAlert'),
		paginate: Paginate,
		// DownloadTableManager
	},
	data() {
		return {
			updating: false,
			limit: 10,
			loading: true,
			status: 'NotSent',
			tab: 2,
			total_invited_customers: 0,
			searchName: '',
			accessType: {
				1: 'Pending',
				2: 'NotSent',
				3: 'Completed',
				4: 'RequestAccess',
			}
		}
	},
	watch: {
		limit: function (newVal, oldVal) {
			this.limit = newVal
			window.localStorage.setItem('invitenum', newVal)
		}
	},
	computed: {
		...mapGetters({
			GET_INVITED_CUSTOMERS: 'portal/GET_SHOP_CUSTOMERS',
			GET_LANG: 'utility/GET_LOCALE_LANG'
		})
	},
	mounted() {
		const num = window.localStorage.getItem('invitenum')
		if (parseInt(num)) {
			this.limit = num
			this.getInvitedCustomers(parseInt(num), this.status, true)
		} else {
			window.localStorage.setItem('invitenum', this.limit)
			this.getInvitedCustomers(this.limit, this.status, true)
		}
		this.getDashboardMetrics()
		window.Bus.$on('get-invited-customers', () => {
			const num = window.localStorage.getItem('invitenum')
			if (parseInt(num)) {
				this.getInvitedCustomers(parseInt(num), this.status, true)
			} else {
				this.getInvitedCustomers(this.limit, this.status, true)
			}
		})
	},
	methods: {
		getDashboardMetrics() {
			this.$store.dispatch('portal/getDashboardMetrics', {data: ''})
				.then(res => {
					this.total_invited_customers = res.data.total_invited_customers
				})
				.catch(err => {
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
				})
		},
		openModalInvite() {
			bus.$emit('open-invite-customer')
		},
		sendInviteData(data) {
			bus.$emit('open-invite-customer', data)
		},
		searchInputTrigger: debounce(function (event) {
			if (event.target.value !== '') {
				this.filterSearch()
			} else {
				this.searchName = ''
				this.getInvitedCustomers(this.limit, this.status, true)
			}
		}, 500),
		filterSearch() {
			this.loading = true
			this.$store.dispatch('portal/getCompanyEndCustomers', {
				limit: this.limit,
				status: this.status,
				isRelevant: true,
				search: this.searchName
			})
				.then(_ => {
					this.loading = false
				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		changePageNumber(number) {
			let page = `?page=${number}&invite_status=${this.status}&limit=${this.limit}`
			if (this.searchName !== '' && this.searchName !== undefined) {
				page = page + `&search=${this.searchName}`
			}
			this.$store.dispatch('portal/getCompanyEndCustomers', {page: this.$services.endpoints.COMPANY_ENDCUSTOMERS + page})
				.then(_ => {
					//
				}).catch((err) => {
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		getInvitedCustomers(limit, status, isRelevant) {
			this.loading = true
			this.$store.dispatch('portal/getCompanyEndCustomers', {limit, status, isRelevant})
				.then(_ => {
					this.loading = false
				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		acceptUserRequest(data) {
			window.Bus.$emit('accept-request', data)
		},
		rejectUserRequest(data) {
			window.Bus.$emit('reject-request', data)
		},
		shopResultNumber(e) {
			this.limit = parseInt(e.target.value)
			this.getInvitedCustomers(parseInt(e.target.value), this.status)
		},
		showTab(number) {
			this.tab = number
			let is_relevant = ''
			this.status = this.accessType[parseInt(number)]
			if (this.tab === 2) {
				is_relevant = true
			}
			this.getInvitedCustomers(parseInt(this.limit), this.status, is_relevant)
		},
		deleteUser(user) {
			const self = this
			this.$confirm(`${this.$t('removeUser')} ${user.customer_name}?`, `${this.$t('warning')}`, {
				confirmButtonText: this.$t('yes'),
				cancelButtonText: this.$t('cancel'),
				type: 'warning',
				beforeClose: (action, instance, done) => {
					if (action === 'confirm') {
						instance.confirmButtonLoading = true
						instance.confirmButtonText = 'Loading...'

						self.$store.dispatch('portal/revokeEndCustomerInvite', {id: user.id})
							.then(_ => {
								done()
								let cIndex = this.GET_INVITED_CUSTOMERS.data.findIndex(item => item.id === user.id)
								if (cIndex > -1) {
									this.GET_INVITED_CUSTOMERS.data.splice(cIndex, 1)
								}
								this.$services.helpers.notification(`${user.customer_name} ${this.$t('removeSuccessful')}`, 'success', this)
								instance.confirmButtonLoading = false
							}).catch((err) => {
							done()
							instance.confirmButtonLoading = false
							if (err.response.status === 400) {
								if (err.response.data.hasOwnProperty('message_swe') && err.response.data.hasOwnProperty('message')) {
									if (this.GET_LANG === 'se') {
										this.$services.helpers.notification(err.response.data.message_swe, 'error', this)
									} else {
										this.$services.helpers.notification(err.response.data.message, 'error', this)
									}
								} else {
									this.$services.helpers.notification(err.response.data.message, 'error', this)
								}
								// this.$services.helpers.notification(this.$t('serverError'), 'error', this)
							} else if (err.response.status === 403) {
								this.$services.helpers.notification(this.$t('permissionDenied'), 'error', this)
							}
						})
					} else {
						done()
					}
				}
			}).then(() => {
				bus.$emit('fetch-users')
			})
				.catch(() => {

				})
		},
		sortCustomerNameAsc() {
			this.$store.commit('portal/SORT_CUSTOMER_NAME_ASC')
		},
		sortShopCustomerNameAsc() {
			this.$store.commit('portal/SORT_SHOP_USER_NAME_ASC')
		},
		sortShopCustomerNameDsc() {
			this.$store.commit('portal/SORT_SHOP_USER_NAME_DSC')
		},
		sortCustomerNameDsc() {
			this.$store.commit('portal/SORT_CUSTOMER_NAME_DESC')
		},
		sortNumberAsc() {
			this.$store.commit('portal/SORT_CUSTOMERS_NUMBER_ASC')
		},
		sortNumberDsc() {
			this.$store.commit('portal/SORT_CUSTOMERS_NUMBER_DESC')
		},
		sortCategoryAsc() {
			this.$store.commit('portal/SORT_CUSTOMERS_CATE_ASC')
		},
		sortCategoryDsc() {
			this.$store.commit('portal/SORT_CUSTOMERS_CATE_DESC')
		},
		sortEmailAsc() {
			this.$store.commit('portal/SORT_CUSTOMERS_EMAIL_ASC')
		},
		sortEmailDsc() {
			this.$store.commit('portal/SORT_CUSTOMERS_EMAIL_DESC')
		},
		sortCustomer: function (sortValue) {
			let page = `?invite_status=${this.status}&limit=${this.limit}&ordering=${sortValue}`
			if (this.searchName !== '' && this.searchName !== undefined) {
				page = page + `&search=${this.searchName}`
			}
			this.$store.dispatch('portal/getCompanyEndCustomers', {page: this.$services.endpoints.COMPANY_ENDCUSTOMERS + page})
				.then(_ => {
					//
				}).catch((err) => {
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.custom-btn-pay {
	background-color: #F167CA !important;
	border-color: #F167CA !important;
	font-weight: 800;
}

.custom-card-section {
	box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
	border-width: 0;
	transition: all .2s;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(26, 54, 126, 0.125);
}

.custom-save-btn {
	background-color: #2CDCA7 !important;
	border-color: #2CDCA7 !important;
	font-weight: 800;
}

.override-form-field {
	border: 0;
	border-bottom: 2px solid rgb(64, 138, 235);
	box-shadow: none;
	background-color: #fff !important;
	border-radius: unset !important;
}

.col-form-label {
	padding-top: 1rem !important;
	// font-size: 14px !important;
}

.form-group {
	margin-bottom: 0.4rem !important;
}

.form-input {
	font-size: 0.8rem !important;
	padding: 0.2rem 0.75rem !important;
}

.col-form-label {
	padding-bottom: unset !important;
	padding-top: 0.3rem !important;
}

.app-main .app-main__inner {
	padding-top: 20px !important;
}

.table td {
	padding: 0.7rem 0.55rem !important;
}

.form-select {
	// border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem 1.5rem !important;
}

.form-control {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.btn-border-all {
	border: 1px solid $theme-secondary-color !important;
	color: $theme-secondary-color !important;
}

.table tbody tr > td {
	font-size: 13px !important;
	color: #6F6F6F !important;
	padding: 0.6rem 0.55rem !important;
}
</style>
